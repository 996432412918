.list-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 202px;
    margin: 0 13px 13px 0;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 20px;
    color: #656565;
    cursor: pointer;

    &:hover {
        border-color: #000;

        .list-card-title {
            max-width: 60%;
        }
    }

    .list-card-image-wrapper {
        height: 300px;
        width: 100%;

        .list-card-image {
            margin: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .list-card-title {
        min-width: 50px;
        margin: 10px;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
