.thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;

    .thumbnail-image {
        background-size: cover;
        background-position: 50%;
        width: 100%;
        height: 100%;
    }
}
