.section {
    margin: 32px 0;
}

.title {
    margin-bottom: 8px;

    color: #6d6d6d;
    font-size: 24px;
    text-transform: uppercase;
}