.icon-button {
    cursor: pointer;
    display: flex;
    align-items: center;

    span {
        display: block;
        margin-left: 4px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &:hover {
        color: #71cfc9;
    }
}
