.smart-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .smart-modal-body {
        position: relative;
        padding: 32px;

        max-width: 90%;
        min-width: 400px;
        max-height: 90%;

        background: #fff;
        border-radius: 7px;

        overflow-y: auto;

        &.auto-width{
            min-width: auto;
        }

        .smart-modal-close-button {
            position: absolute;
            top: 16px;
            right: 16px;

            cursor: pointer;

            &:hover {
                color: #71cfc9
            }
        }
    }
}
