@keyframes spinLoading {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.imagedit {
    position: relative;
    display: flex;

    .imagedit-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        background: rgba(0, 0, 0, 0.5);
        color: white;
        cursor: pointer;
        font-size: 40px;
    }
}

.imagedit-modal {
    display: flex;
    flex-direction: column;

    .crop-container {
        display: flex;
        justify-content: center;
        max-width: 80vw;
        max-height: 60vh;

        img {
            max-width: 80vw;
            max-height: 60vh;
        }

        .crop-image {
            background: #ccc;

            max-width: 50%;
            max-height: 50%;
        }

        .actions {
            display: flex;
            flex-direction: column;
            font-size: 28px;

            margin-left: 16px;

            &>* {
                cursor: pointer;
                margin: 8px;

                &:hover {
                    color: #71cfc9;
                }
            }
        }


    }

    .images-error {
        color: red;
    }
    .crop-stats {
        align-self: flex-end;

        .dimensions-warning {
            color: red;
        }
    }

    .crop-image-loading, .crop-image-unavailable {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        min-width: 300px;
        height: 50%;
        min-height: 300px;

        color: #ccc;
        font-size: 120px;
    }

    .crop-image-loading {
        font-size: 40px;
        width: 100%;
    }

    .imagedit-save {
        align-self: flex-end;
        margin-top: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .imagedit-reorderer {
        display: flex;
        justify-content: center;
        align-items: center;

        .orderable-gallery {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            max-width: 60%;

            .gallery-image {
                border: solid 3px transparent;
                margin: 3px;
            }

            .gallery-image-current {
                border: 1px solid #81d4cf;
            }

            .thumbnail-image {
                min-width: 50px;
                min-height: 50px;
            }

            .orderable-gallery-add-button {
                cursor: pointer;
                font-size: 20px;
                margin: 8px;

                &:hover {
                    color: #71cfc9;
                }
            }
        }
    }
    .loadingSaveButton{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #fff;
        border-top: 2px solid #eee;
        animation: spinLoading 1.5s linear infinite
    }
}
