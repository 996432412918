.the-brand {
    .the-brand-info {
        max-width: 1180px;
        width: 1180px;

        .mySwiper{
            .swiper-pagination{
                .swiper-pagination-bullet{
                    height: 8px;
                    width: 8px;
                    border: 3px solid #FFF !important;
                    border-radius: 50%;
                    background-color: transparent !important;
                    opacity: 1 !important;

                    &.swiper-pagination-bullet-active{
                        background-color: #FFF !important;
                    }
                }
            }
        }

        .brand-stats {
            display: flex;
            justify-content: space-between;
            border: solid 1px transparent;
            gap: 1rem;

            .subtitle {
                color: #4a4a4a;
                font-size: 9px;
                font-style: italic;
                font-weight: 600;
            }

            .value {
                color: #000;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .the-brand-info-images {
            width: 605px;
            height: 417px;
            overflow: hidden;

            .placeholder-image {
                width: 605px;
                height: 417px;
                font-size: 56px;
            }

            img {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

.the-brand-info-button{
    margin: 15px 0 0 20px;

    
}

.featured-infoblock-modal-content{
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .the-brand-info-button-field {
        display: flex;
        align-items: center;
    
        margin: 8px 0;
    
        span {
            margin: 0 8px;
        }
    
        input {
            width: 400px;
        }

    }    
}