.bottom-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 8px 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: solid 1px #ccc;
    background: white;
    z-index: 9;
    box-sizing: border-box;

    button {
        margin-left: 20px
    }
}
