.texttoggledit {
    border: solid 1px transparent;
    cursor: pointer;
    padding: 16px;
    margin: -16px;
    box-sizing: border-box;

    &.texttoggledit-hovered, &.texttoggledit-active {
        border-color: #ccc;

            .texttoggledit-icon {
                color: #000;
            }
    }

    &.texttoggledit-active {
        border-color: #71cfc9;
        border-width: 2px;
    }

    .texttoggledit-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        .texttoggledit-placeholder {
            color: #ccc;
        }

        span{
            word-break: break-word;
        }
    }

    .texttoggledit-icon {
        color: #ccc;
        margin-left: 16px;
    }

    .texttoggledit-editor {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .texttoggledit-input {
            width: 100%;
            height: 100%;
            border: none;
            margin: 0;
            padding: 0;
            background: transparent;
            color: inherit;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-align: inherit;
            resize: none;
        }
        
        .texttoggledit-editor-save {
            position: absolute;
            bottom: -37px;
            right: 0;
            font-size: 14px;
            font-weight: normal;
            z-index: 7;
        }

        .texttoggledit-editor-chars-left {
            background: white;
            padding: 0 8px;
            margin-right: 8px;
        }

        &.texttoggledit-async-error .texttoggledit-editor-save button {
            background: #ea4848;
            border-color: #ea4848;
        }
    }
}
