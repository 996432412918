.placeholder-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: solid 1px #ccc;
    box-sizing: border-box;

    width: 100%;
    height: 100%;

    .placeholder-image-text {
        font-size: 14px;
        text-transform: uppercase;
    }
}
