.youtube-container {
    width: 100%;
    height: 100%;
    max-width: 605px;
    max-height: 417px;
    min-height: 200px;

    &.editor {
        width: 605px;
        height: 417px;
    }

    &.info-block {
        width: 324px;
        height: 420px;
    }
}
