html, body, #root {
    height: 100%;
}

.login-page {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    // background: #5e5f5e;
    background: #eee;

    .login-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -100px;
        padding: 48px;

        background: #fff;
        border: solid 1px #ccc;
        border-radius: 16px;
        box-shadow: 3px 2px 10px rgba(0,0,0,.5);
    }

    
    .logo {
        width: 300px;
        margin-bottom: 16px;
    }

    .title {
        color: #333;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    .login-field {
        margin-top: 4px;
        margin-bottom: 4px;
    }

    .login-button {
        margin-top: 16px;

        svg {
            display: inline-block;
            margin-right: 4px;
        }
    }
}
