.sizechart-card {
    width: 200px;

    margin: 8px;
    padding: 4px;

    border: solid 1px #ddd;

    &:hover {
        border-color: #999;

        .sizechart-actions {
            visibility: visible;
        }
    }

    .sizechart-picker {
        margin-top: 24px;
    }

    .sizechart-actions {
        display: flex;
        justify-content: flex-end;
        visibility: hidden;
    }

    .sizechart-image {
        margin-top: 32px;
        height: 100px;
        overflow: hidden;
    }
    .edit-chart {
        margin-top: 32px;
    }
}
