@import './common.scss';

body {
  margin: 0;
  font-family: "PolySans-Slim";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rmdp-wrapper {
  border: 1px solid $rmdp-secondary;
  box-shadow: 0 0 5px $rmdp-secondary;
}

.rmdp-panel-body li {
background-color: $rmdp-primary;
  box-shadow: 0 0 2px $rmdp-secondary;
}

.rmdp-week-day {
  color: $rmdp-primary;
}

.rmdp-day.rmdp-deactive {
  color: $rmdp-secondary;
}
.rmdp-range-hover {
  background-color: $rmdp-primary-hover;
  box-shadow: 0 0 3px $rmdp-shadow;
}

.rmdp-range {
  background-color: $rmdp-primary-hover;
  box-shadow: 0 0 3px $rmdp-shadow;
}

.rmdp-arrow {
  border: solid $rmdp-primary;
  border-width: 0 2px 2px 0;
}

.rmdp-arrow-container:hover {
  background-color: $rmdp-primary;
  box-shadow: 0 0 3px $rmdp-secondary;
}

.rmdp-panel-body::-webkit-scrollbar-thumb {
  background: $rmdp-primary;
}

.rmdp-day.rmdp-today span {
  background-color: $rmdp-today;
}

.rmdp-rtl .rmdp-panel {
  border-left: unset;
  border-right: 1px solid $rmdp-secondary;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: $rmdp-primary;
  box-shadow: 0 0 3px $rmdp-shadow;
}

.rmdp-day:not(.rmdp-day-hidden) span:hover {
  background-color: $rmdp-hover !important;
}

.b-deselect {
  color: $rmdp-primary;
  background-color: white;
}

.rmdp-action-button {
  color: $rmdp-primary;
}

.rmdp-button:not(.rmdp-action-button) {
  background-color: $rmdp-primary;
}

.rmdp-button:not(.rmdp-action-button):hover {
  background-color: $rmdp-primary;
}

.rmdp-day.rmdp-range.end {
  border-radius: 0px;
  background: $rmdp-primary;
}

.rmdp-day.rmdp-range.start {
  border-radius: 0px;
  background: $rmdp-primary;
}