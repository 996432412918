.international-retailers-modal-actions.save-button {
  margin-top: 15px;
}

.international-retailers {
  margin: 24px 0;

  span {
    cursor: pointer;
    color: #000000;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;

    font-size: 11px;
    line-height: 20px;
    font-weight: 600;
  }
}

.international-retailers-modal-content {
  max-height: 40vh;
  overflow-y: scroll;
}
