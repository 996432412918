@import '../../../common.scss';

.container--card {
    display: flex;
    flex-direction: column;
    height: fit-content;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    font-family: 'Montserrat';
    padding: 1rem;
    border-radius: 0.5rem;
    gap: 0.2rem;
    width: 16%;
    min-width: 130px;
    min-height: 110px;
    background: white;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
        transform: scale(0.97);
    }

    span {
        all: unset;
        width: -moz-fit-content;
        width: fit-content;
        height: fit-content;
    }

    p {
        all: unset;
        width: -moz-fit-content;
        width: fit-content;
        height: fit-content;
    }

    .title {
        color: $light-gray;
    }

    .number {
        color: $gray;
        font-size: 3rem;
    }

    .average {
        display: flex;
        margin: 0px;
        align-items: center;
        gap: 5px;
        color: $black;

        .arrow__down {
            color: $red;
            font-size: 20px;
        }

        .arrow__up {
            color: $green;
        }
    }

    .flex {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .column {
        display: flex;
        flex-direction: column;
    }
}

.container--card--active {
    display: flex;
    flex-direction: column;
    height: fit-content;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    font-family: 'Montserrat';
    padding: 1rem;
    border-radius: 0.5rem;
    gap: 0.2rem;
    width: 16%;
    min-width: 130px;
    min-height: 110px;
    background: #51d5e1;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
        transform: scale(0.97);
    }

    span {
        all: unset;
        width: -moz-fit-content;
        width: fit-content;
        height: fit-content;
    }

    p {
        all: unset;
        width: -moz-fit-content;
        width: fit-content;
        height: fit-content;
    }

    .title {
        color: $light-gray;
    }

    .number {
        color: $gray;
        font-size: 3rem;
    }

    .average {
        display: flex;
        margin: 0px;
        align-items: center;
        gap: 5px;
        color: $black;

        .arrow__down {
            color: $red;
            font-size: 20px;
        }

        .arrow__up {
            color: $green;
        }
    }

    .flex {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .column {
        display: flex;
        flex-direction: column;
    }
}

.cards--bellow__individual {
    display: flex;
    flex-direction: column;
    height: fit-content;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    font-family: 'Montserrat';
    padding: 1rem;
    border-radius: 1rem;
    gap: 0.2rem;
    width: 35%;
    min-width: 180px;
    min-height: 100px;
    justify-content: space-between;

    @include desktop-xl {
        width: 100%;
    }

    span {
        all: unset;
        width: -moz-fit-content;
        width: fit-content;
        height: fit-content;
    }

    p {
        all: unset;
        width: -moz-fit-content;
        width: fit-content;
        height: fit-content;
    }

    .title {
        color: $light-gray;
    }

    .number {
        color: $gray;
        font-size: 2.7rem;
    }

    .average {
        display: flex;
        margin: 0px;
        align-items: center;
        gap: 5px;
        color: $black;

        .arrow__down {
            color: $red;
            font-size: 20px;
        }

        .arrow__up {
            color: $green;
        }
    }

    .flex {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }

    .column {
        display: flex;
        flex-direction: column;
    }
}
