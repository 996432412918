.showcase {
    display: flex;

    .title h2 {
        font-size: 40px;
        padding: 25px;
        margin: 0;
        margin-top: 32px;
    }

    .content {
        flex-grow: 1;
        z-index: 2;

        .title {
            margin-left: -150px;
            background: #fff;
            line-height: 40px;
        }
        .bottom-content {
            color: #4a4a4a;
            font-size: 14px;
            line-height: 26px;
        }
    }

    &.image-position-left {
        .title h2 {
            padding-right: 0;
        }

        .image {
            margin-right: 70px;
            position: relative;

            .dots{
                position: absolute;
                display: flex;
                gap: 10px;
                bottom: 20px;
                z-index: 2;
                justify-content: center;
                width: 100%;

                span{
                    height: 10px;
                    width: 10px;
                    background-color: #8f8f8f;
                    border-radius: 50%;
                }

                span:first-child {
                    background-color: #272626;
                }
            }
        }
    }
    
    &.image-position-right {
        flex-direction: row-reverse;

        .title h2 {
            padding-left: 0;
        }
        .content {
            .title {
                margin-left: 0px;
                margin-right: -150px;
                text-align: right;
            }
        }
        
        .image {
            margin-left: 70px;
            position: relative;

            .dots{
                position: absolute;
                display: flex;
                gap: 10px;
                bottom: 20px;
                z-index: 2;
                justify-content: center;
                width: 100%;

                span{
                    height: 10px;
                    width: 10px;
                    background-color: #8f8f8f;
                    border-radius: 50%;
                }

                span:first-child {
                    background-color: #272626;
                }
            }
        }
    }

}
