@import "~@openfonts/open-sans_all/index.css";
@import "~@openfonts/montserrat_all/index.css";


:root {
  scroll-behavior: smooth;
}

/** Fonts */
@mixin OpenSansFont {
  font-family: 'Open Sans';
}
@mixin MontserratFont {
  font-family: 'Montserrat';
}

@font-face {
  font-family: "PolySans-Slim";
  src: url(./fonts/PolySans-Slim.woff) format("woff"),
    url(./fonts/PolySans-Slim.woff2) format("woff2");
}

/** Layout */
@mixin Center {
    display: flex;
    justify-content: center;
    align-items: center;
}
@mixin HorizontalCenter {
    display: flex;
    align-items: center;
}
@mixin VerticalCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.horizontal-center {
    @include HorizontalCenter();
}

/** Colours */
$primary-color: #71cfc9;
$primary-color-darker: #71cfc9;
$primary-color-accent: #71cfc9;
$light-gray: #50545F8F;
$gray: #50545F;
$black: #000000;
$green: #3BAC13FA;
$red: #F46D6DFA;
$purple: #9c59e4;
$graysh: #00000070;
$blackish: #272626;
$exw-eur: #A1A1A1;
$blackish-hover: #3D3C3C;
$exw-usd: #3B3B3B;
$landed-eur: #C0C0C0;
$landed-usa: #AEAEAE;
$rmdp-primary: rgb(0, 0, 28);
$rmdp-primary-hover: rgba(0, 0, 51, 0.8);
$rmdp-secondary: #0f004040;
$rmdp-shadow: #cfcfcf;
$rmdp-today: rgba(0, 0, 51, 0.9);
$rmdp-hover: #bfbfbf;

.field--required .MuiFormLabel-root {
  font-weight: bolder;
}
%mx-auto {
  margin-left: auto;
  margin-right: auto;
}

%my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

%prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

%container {
  width: 85%;
  max-width: 1250px;
}

// mixin responsividade
@mixin cellphone {
  @media (max-width: #{$cellphone-width}) {
      @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
      @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktop-width}) {
      @content;
  }
}

@mixin desktop-xl {
  @media (max-width: #{$desktop-xl-width}) {
      @content;
  }
}

@mixin desktop-2xl {
  @media (max-width: #{$desktop-2xl-width}) {
      @content;
  }
}

@function text($bg) {
  @if (lightness($bg) > 50) {
      @return #000;
  } @else {
      @return #fff;
  }
}

@mixin dc($color) {
  background: $color;
  color: text($color);
}

$cellphone-width: 640px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-xl-width: 1250px;
$desktop-2xl-width: 1586px;
