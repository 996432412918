.edit-brand-profile {
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cover-image-container {
        width: 100%;
        position: relative;

        .cover-image {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            filter: brightness(80%);

            height: 500px;

            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            // background-attachment: fixed;

            .logo {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .logo-backdrop {
                    position: absolute;
                    z-index: 1;
                }
                .logo-image {
                    max-width: 357px;
                    max-height: 357px;
                    z-index: 3;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                    
                }
            }
        }

        .cover-image-imagedit {
            display: block;
        }
        
        .cover-image-overlay {
            justify-content: flex-end;
            align-items: flex-end;
            box-sizing: border-box;
            padding: 0 40px 40px 0;
        }
    }

    .tab-menu {
        display: flex;
        justify-content: space-evenly;
        z-index: 7;

        margin-top: -55px;
        margin-bottom: 50px;
        padding: 20px;
        width: 460px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: white;

        .tab-selector, .separator {
            margin: 0 16px;


            font-size: 12px;
            letter-spacing: 2px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .tab-selector {
            cursor: pointer;
            color: #A7A9AF;

            &:hover {
                color: #d8d8d8;
            }
        }

        .tab-selector-active {
            color: #50545f;
        }
    }



    padding-bottom: 60px;
}
