.terms-and-conditions-modal-actions.save-button {
  margin-top: 15px;
}

.terms-and-conditions-modal-content {
  max-width: 925px;

  .quill .ql-container, .quill .ql-editor {
    max-height: 50vh;
  }
}

.terms-and-conditions {
  width: 925px;
  margin: 24px 12px;

  span {
    cursor: pointer;
    color: #71cfc9;

    font-size: 11px;
    line-height: 20px;
    font-weight: 600;

    text-transform: uppercase;
  }
}
