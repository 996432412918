@import '../../common.scss';

.dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 3.5rem;
    margin-top: 2rem;

    &--container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1280px;

        &--periodSelector {
            display: flex;
            justify-content: space-between;
            width: 100%;

            & .periodSelector {
                display: flex;
                width: -moz-fit-content;
                width: fit-content;
                gap: 2rem;
                justify-content: center;
                align-items: center;

                @include cellphone {
                    align-items: start;
                    flex-direction: column;
                    gap: 0;
                }
            }
        }
    }

    .inputChangePicker {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &--button.active {
            transform: rotate(90deg);
        }

        &--button {
            all: unset;
            display: flex;
            height: 20px;
            width: 20px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

            &--svg {
                width: 100%;
                height: 100%;
                fill: $blackish-hover;
            }
        }

        &--list {
            all: unset;
            position: absolute;
            width: 140px;
            list-style: none;
            display: flex;
            left: 40px;
            flex-direction: column;
            background: $blackish;
            color: white;
            gap: 0.5rem;
            padding: 1rem;
            border-radius: 0.5rem;
            box-shadow: 10px 6px 44px 1px rgba(0, 0, 0, 0.1);

            &--item {
                display: flex;
                gap: 0.3rem;

                & input {
                    all: unset;
                    display: flex;
                    background: #fff;
                    content: '';
                    height: 20px;
                    width: 20px;
                    border-radius: 5rem;
                    position: relative;
                    cursor: pointer;
                    box-shadow: 0px 2px 3px 1px #00000020;

                    &:checked {
                        all: unset;
                        display: flex;
                        background: #fff;
                        content: '';
                        height: 20px;
                        width: 20px;
                        border-radius: 5rem;
                        position: relative;
                        cursor: pointer;
                        box-shadow: 0px 2px 3px 1px #00000020;

                        &::after {
                            position: absolute;
                            display: flex;
                            content: '';
                            height: 15px;
                            width: 15px;
                            background: $blackish;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            border-radius: 5rem;
                        }
                    }
                }

                & label {
                    cursor: pointer;
                }
            }
        }
    }
}

.dashboard--balance {
    display: flex;
    margin-top: 2rem;
    gap: 0.5rem;
    width: 100%;
    justify-content: space-between;
    padding: 1rem 0rem;

    @include desktop {
        overflow-x: scroll;
    }
}
.chart {
    position: relative;

    .chart--desc {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 2rem;
        height: fit-content;
        text-align: center;
        align-items: center;

        @include tablet {
            flex-direction: column;
            gap: 0rem;
        }

        &__current {
            display: flex;
            height: fit-content;
            width: fit-content;
            align-items: center;
            text-align: center;
            gap: 0.5rem;

            & span {
                color: $purple;
                font-size: 20px;
                border: 2px solid $purple;
                padding: 0;
                width: 15px;
                border-radius: 5rem;
            }
        }

        &__last {
            display: flex;
            height: fit-content;
            width: fit-content;
            align-items: center;
            text-align: center;
            gap: 0.5rem;

            & span {
                color: $graysh;
                font-size: 20px;
                border: 2px solid $graysh;
                padding: 0;
                width: 15px;
                border-radius: 5rem;
            }
        }
    }
}

.dashboard--chart {
    margin-top: 5rem;
    width: 100%;
    height: 400px;
    margin-left: -1rem;

    @include desktop-2xl {
        height: 350px;
    }
}

.chart-cards {
    margin-top: 6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    .final--content {
        display: flex;
        justify-content: space-between;

        .bar--chart {
            width: 60%;
            height: 450px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 4.5rem;

            .description {
                display: flex;
                gap: 1rem;

                .span__elements {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    & span {
                        font-size: 20px;
                        padding: 0;
                        width: 0px;
                        height: 0px;
                        border-radius: 5rem;
                    }
                }
            }
        }

        .cards--bellow {
            width: 38%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .cards--download {
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
        justify-content: space-between;
        display: flex;
        margin-left: auto;

        p {
            width: 212px;
            text-align: center;
        }
    }
}
