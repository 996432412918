.lookbooks{
  width: 100%;
  padding: 1rem 0;
  gap: 2rem;

  .images{
    padding: 2rem 1rem 3rem;
    border: 1px solid #FFF;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .actions{
      width: 100%;
      align-self: flex-end;
      display: flex;
      svg{
        opacity: 0;
        font-size: 1.2rem;
  
        &:hover{
          color: #71CFC9;
        }
      }

      .container-icons {
        margin: 0 auto;
        svg {
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }

    &:hover{
      border-color:#c0c0c0;

      .actions{
        
        svg{
          opacity: 1;
        }
      }
    }

    .eight-small{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;

      .item{
        height: 285px;
        position: relative;
        .imagedit{
          height: 100%;
          width: 100%;
          img{
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        .title-box{
          position: absolute;
          background-color: #FFF;
          bottom: 0;
          width: 200px;
          height: auto;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 99999;
          text-align: center;
          padding: 5px;

          .texttoggledit{
            text-align: center;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    .one-big-four-small{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;

      .left{
        .item{
          height: 600px;
          width: 575px;
          position: relative;
          .imagedit{
            height: 100%;
            width: 100%;
            img{
              object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }

          .title-box{
            position: absolute;
            background-color: #FFF;
            bottom: 0;
            width: 200px;
            height: auto;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 99999;
            text-align: center;
            padding: 5px;

            .texttoggledit{
              text-align: center;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }

      .right{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        .item{
          height: 285px;
          position: relative;
          .imagedit{
            height: 100%;
            width: 100%;
            img{
              object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }

          .title-box{
            position: absolute;
            background-color: #FFF;
            bottom: 0;
            width: 200px;
            height: auto;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 99999;
            text-align: center;
            padding: 5px;

            .texttoggledit{
              text-align: center;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .two-big{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      position: relative;

      .item{
        height: 585px;
        .imagedit{
          height: 100%;
          width: 100%;
          img{
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        .title-box{
          position: absolute;
          background-color: #FFF;
          bottom: 0;
          width: 200px;
          height: auto;
          left: 0;
          right: 0;
          margin: auto;
          z-index: 99999;
          text-align: center;
          padding: 5px;

          .texttoggledit{
            text-align: center;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

  }


}