@import '../../common.scss';

.simple-title {
	font-family: "PolySans-Slim";
	font-size: 72px;
	color: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 50px 0 32px 0;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 2px;
	padding: 0 20px;
	width: fit-content;

	position: absolute;
	z-index: 2;
	top: 50%;  /* position the top  edge of the element at the middle of the parent */
	left: 50%; /* position the left edge of the element at the middle of the parent */

	transform: translate(-50%, -150%);

	.underline {
		display: block;
		width: 40px;
		height: 2px;
		background: #000000;
		margin: 0 25px;
		font-weight: 600;
    }
    
    // .title {
    //     box-sizing: border-box;
    //     border: solid 1px transparent;
    //     padding: 16px;
    // }
    // .title:hover {
    //     border: solid 1px #ccc;
    // }
}

@media (max-width: 768px) {
	.simple-title {
		font-size: 20px;
		white-space: nowrap;
		margin: 30px 0 40px ;
		padding: 0px;
		line-height: 23px;

		.title {
			margin: 0 30px;
		}

		.underline {
			background: #4A4A4A;
			width: 50px;
			margin: 0;
			height: 1px;
		}
	}
}
