.fields {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 5rem;
}


.fieldGroup {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
}

.priceTooltips {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    div{
        display: grid;
        grid-template-columns: 1fr auto;
    }

    .tooltip {
        // margin-left: 16px;
        // margin-right: 32px;
    }

    .MuiTextField-root {
        width: 100%;
    }
}

.orderWindow, .productionWindow {
    display: flex;
    justify-content: space-between;

    &>* {
        margin-right: 32px;
        width: 200px;
    }
}

.subtitle {
    font-size: 24px;
    color: #6d6d6d;
    text-transform: uppercase;
    margin-top: 48px !important;
    margin-bottom: 16px;
}

.withMoreMargin {
    margin-top: 80px !important;
}

.listImage {
    &, .imageedit, img {
        width: 264px;
        height: 300px;
        object-fit: cover;
    }

    .placeholder-image {
        width: 264px;
        height: 300px;
    }
}

