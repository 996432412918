.iconButton {
    display: flex;
    align-items: center;

    background: none;
    border: none;
    padding: 0;

    cursor: pointer;

    span {
        margin-left: 4px;

        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &:hover {
        color: #71cfc9;
    }
}
