.add-sizechart-form {
    .sizechart-name-input {
        width: 200px;
        font-size: 16px;
        outline: none;
        border: solid 1px #ccc;
        border-radius: 7px;
        padding: 8px;
    }

    .save-button {
        margin-top: 15px;
    }
}
