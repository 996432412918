.collectionProducts{
    margin-top: 48px !important;
}

.collectionProductList {
    display: flex;
    flex-wrap: wrap;

    .listCard {
        position: relative;
        .actions {
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 10px;
            font-size: 18px;
            visibility: hidden;

            display: flex;

            &>* {
                margin-left: 8px;
            }
        }

        .editProductAction {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 10px;
            font-size: 18px;
            visibility: hidden;

            a {
                color: inherit;
            }
        }

        &:hover {
            .actions, .editProductAction {
                visibility: visible;
            }
        }
    }
}

.addButton {
    margin-top: 8px;
}