.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;

  .modal-close-button {
    position: absolute;
    top: 8px;
    right: 8px;

    cursor: pointer;
    color: #999;
    padding: 4px;

    &:hover {
      color: #000;
    }
  }
}

.modal-title {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;

  button {
    margin-top: 15px;
  }
}
