.history-social-media {
    display: flex;
    justify-content: flex-end;

    align-items: center;

    padding: 16px;
    
    border: solid 1px transparent;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;

    &:hover {
        border-color: #ccc;
    }

    .history-social-media-icon {
        width: 25px;
        height: 25px;

        margin: 0 4px;
    }

    .history-social-media-edit-icon {
        color: #ccc;
        margin-left: 8px;
    }

}


.history-social-media-modal-field {
    display: flex;
    align-items: center;

    margin: 8px 0;

    img {
        width: 25px;
        height: 25px;
        margin-right: 8px;

    }
    // span {
    //     margin: 0 8px;
    // }

    input {
        width: 400px;
    }

    // .history-social-media-modal-icon {
    // }
}
