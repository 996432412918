.the-brand {
    display: flex;
    flex-direction: column;
    align-items: center;


    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 40px;
    }

    .read-more-button {
        margin: 20px 0 28px;
        cursor: pointer;
        font-size: 11px;
        line-height: 14px;
        font-weight: 600;
        color: black;
        border-bottom: solid 2px black;
        display: inline-block;
    }

    .container-brand-style{
        max-width: 1180px;
        position: relative;
        margin-top: 80px;
        .text{
            padding-left: 3rem;
        
            .texttoggleedit{
                max-width: 100%;
            }
        }
        .mySwiper{
            width: 1180px;
            position: unset !important;
            
            .swiper-button-next{
                right: -30px;
                top: 55%;
                color: #272626;
            }

            .swiper-button-prev{
                left: -30px;
                top: 55%;
                color: #272626;
            }

            .swiper-pagination{
                .swiper-pagination-bullet{
                    &.swiper-pagination-bullet-active{
                        background-color: #272626;
                    }
                }
            }
        }
    }


}
