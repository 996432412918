.footer {
    width: 100%;
    height: 305px;

    .edit-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 8px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .texttoggledit-active {
        margin: 0px;
    }

    .content {
        width: 470px;

        color: white;
        text-align: center;
        z-index: 3;
    }

    .title {
        font-size: 40px;
        text-transform: uppercase;
    }

    .text {
        margin: 30px auto;
        text-shadow: 0 2px 1px rgba(0,0,0,.6);
        font-size: 16px;
        line-height: 22px;
    }

    .footer-image, .footer-image-imagedit {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-image-imagedit {
        .texttoggledit:hover {
            .texttoggledit-icon {
                color: white;
            }
        }
    }

    .footer-image {
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        position: relative;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000;
            opacity: .5;
            z-index: 1;
        }

        &.footer-image--no-overlay {
            &:before {
                opacity: 0;
            }

            .texttoggledit {
                background: rgba(0,0,0,.3);
            }
        }
    }
            
    .footer-image-overlay {
        justify-content: flex-end;
        align-items: flex-end;
        box-sizing: border-box;
        padding: 0 40px 40px 0;
    }
}
