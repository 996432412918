.history {
    display: flex;
    flex-direction: column;
    align-items: center;

    .history-info {
        width: 1057px;
        display: flex;
        justify-content: space-between;

        .history-info-images {
            width: 300px;
            height: 450px;

            img {
                width: 100%;
            }
            
            .placeholder-image {
                width: 300px;
                height: 450px;
                font-size: 56px;
            }
        }

        .brand-stats {
            display: flex;
            flex-wrap: wrap;
            width: 702px;

            margin-top: 55px;

            .entry {
                width: 175px;
                margin-bottom: 28px;

                .subtitle, .value {
                    max-width: 154px;
                }

                .subtitle {
                    margin-bottom: -28px;

                    color: #4a4a4a;
                    font-size: 9px;
                    font-style: italic;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                .value {
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                }

                &:hover {
                    .actions {
                        display: block;
                    }
                }
                .actions {
                    display: none;
                    padding-top: 16px;
                    &>* {
                        cursor: pointer;
                        font-size: 14px;

                        &:hover {
                            color: #71cfc9;
                        }
                    }
                }
            }

            .add-entry {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .add-entry-button {
                    cursor: pointer;
                    font-size: 24px;

                    &:hover {
                        color: #71cfc9;
                    }
                }
            }
        }

        .bottom-info {
            display: flex;
            justify-content: flex-end;
        }
    }
    

    // .history-info {
    //     width: 1057px;

    //     .history-stats {
    //         display: flex;
    //         justify-content: space-between;
    //         border: solid 1px transparent;
    //         flex-wrap: wrap;

    //         .entry {
    //             width: 175px;
    //             margin-bottom: 28px;
    //         }

  
    //     }

    //     .bottom-content {
    //         display: flex;
    //         justify-content: flex-end;

    //         .history-social-media {
    //             display: flex;
    //             justify-content: flex-end;
            
    //             align-items: center;

    //             padding: 16px;
                
    //             border: solid 1px transparent;
    //             cursor: pointer;
    //             font-size: 13px;
    //             text-transform: uppercase;

    //             &:hover {
    //                 border-color: #ccc;
    //             }

    //             .history-social-media-icon {
    //                 width: 25px;
    //                 height: 25px;

    //                 margin: 0 4px;
    //             }

    //             .history-social-media-edit-icon {
    //                 color: #ccc;
    //                 margin-left: 8px;
    //             }
    //         }
    //     }

    //     .history-info-images {
    //         max-width: 300px;
    //         max-height: 450px;
            
    //         .placeholder-image {
    //             width: 300px;
    //             height: 450px;
    //             font-size: 56px;
    //         }
    //     }
    // }

    .history-infoblocks {
        margin-top: 32px;
    }

    .container-lookbooks{
        max-width: 1180px;
        position: relative;
        .text{
            padding-left: 3rem;
        
            .texttoggleedit{
                max-width: 100%;
            }
        }
        .mySwiper{
            width: 1180px;
            position: unset !important;
            
            .swiper-button-next{
                right: -30px;
                top: 55%;
                color: #272626;
            }

            .swiper-button-prev{
                left: -30px;
                top: 55%;
                color: #272626;
            }

            .swiper-pagination{
                .swiper-pagination-bullet{
                    &.swiper-pagination-bullet-active{
                        background-color: #272626;
                    }
                }
            }
        }

        
    }
}
