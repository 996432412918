.error-message {
    padding: 8px;
    margin: 8px 0;
    border-radius: 8px;
    border: solid 1px #ea4848;
    color: #ea4848;
}

.error-text {
    color: #ea4848;
}