.measurement-card {
    width: 200px;

    margin: 8px;
    padding: 4px;

    border: solid 1px #ddd;

    &:hover {
        border-color: #999;

        .measurement-actions {
            visibility: visible;
        }
    }

    .measurement-picker {
        margin-top: 24px;
    }

    .measurement-actions {
        display: flex;
        justify-content: flex-end;
        visibility: hidden;
    }
}
