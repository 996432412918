.infoblocks {
    width: 941px;

    .infoblock {
        border: solid 2px transparent;
        padding: 16px;
        margin: 8px 0;

        &:hover {
            border-color: #ccc;

            .actions {
                display: flex;
                visibility: visible;
            }
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            visibility: hidden;

            font-size: 20px;
            margin-right: 4px;

            & > * {
                margin: 8px 4px;
                padding: 0 8px;
                cursor: pointer;

                &:hover {
                    color: #71cfc9;
                }
            }
        }

        .placeholder-image {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 56px;
            width: 100%;
            height: 100%;
            border: solid 1px #ccc;
            box-sizing: border-box;
            color: #ccc;
        }

        .image {
            height: fit-content;
            width: 50%;
        }

        .image-orientation-portrait {
            .image img,
            .placeholder-image {
                width: 300px;
                height: 450px;
            }

            iframe {
                width: 300px;
                height: 450px;
            }
        }

        .image-orientation-landscape {
            .image img,
            .placeholder-image {
                width: 500px;
                height: 300px;
            }

            iframe {
                width: 500px;
                height: 300px;
            }
        }

        .read-more-button {
            margin: 20px 0 16px;
            cursor: pointer;
            font-size: 11px;
            font-weight: 600;
            color: black;
            border-bottom: solid 1px black;
            display: inline;
        }
    }

    .add-infoblock {
        display: flex;
        align-items: center;

        cursor: pointer;

        margin: 8px 0;

        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;

        div {
            display: inline-block;
            margin-left: 4px;
        }

        &:hover {
            color: #71cfc9;
        }
    }
}

.infoblocks-menu {
    .infoblock-image-orientation,
    .infoblock-image-position {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 270px;
    }
}

.mat-ripple-element {
    display: none;
}
