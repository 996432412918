@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.page-loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 120px 0;
    
    .page-loading-spinner {
        display: flex;
        flex-direction: column;
        align-items: center;

        .spinner {
            width: 72px;
            height: 72px;
            animation-name: spin;
            animation-duration: 1000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear; 
        }
        .text {
            color: #ccc;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
}
