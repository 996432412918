.addProductToCollection {
    min-width: 400px;
    max-height: 90%;

    .productFilter {
        display: flex;
        align-items: center;
    }

    .productList {
        min-height: 48px;
        margin-top: 16px;

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .selectedProduct {
            border: solid 3px #71cfc9;
        }

        .disabledProduct {
            background: #ccc;
            color: grey !important;
            border-color: #ccc !important;
            cursor: default !important;
        }
    }
}

