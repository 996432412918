.bottomBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 8px 48px;
    
    display: flex;
    justify-content: flex-end;
    align-items: center;

    background: white;
    border-top: solid 1px #ccc;
    box-sizing: border-box;
    z-index: 9;
}

.asyncButton {
    margin-left: 20px;
}
.failureMsg {
    color: red;
}
.successMsg {
    color: green;
}