.header {
    .topbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 70px;
        background: #fff;
        padding: 0 32px;
        box-sizing: border-box;

        .links {
            display: flex;
            align-items: center;

            .logout-button {
                margin-left: 32px;
            }
        }

        .logo {
            max-height: 80%;
        }

        a {
            margin: 0 20px;
            color: #50545f;
            font-size: 20px;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                color: #000;
            }
        }
    }

    .bottombar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: #272626;

        a {
            margin: 0 8px;
            padding: 16px;

            color: #fff;
            font-size: 16px;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }
}
