.primaryButton {
    padding: 2px 20px;

    background: #272626;
    border: solid 1px transparent;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
    color: #fff;
    cursor: pointer;
    font-size: 11px;
    line-height: 33px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;

    // &:hover {
    //     background: #58C6BE;
    //     border: solid 1px #39A7A0; 
    // }

    &[disabled], &[disabled]:hover {
        background: #ccc;
        border: solid 1px transparent;
    }
}
