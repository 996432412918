@import '../../../common.scss';

.tour-container{
    display: flex;
    height: fit-content;
    width: fit-content;

    .btn-tour {
        all: unset;
        background: $blackish;
        color: white;
        padding: 0.5rem 0.9rem;
        border-radius: 5rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: $blackish-hover;
        }
    }
}
