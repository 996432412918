.orderable-grid-item {
    opacity: 1;

    .orderable-grid-handle {
        cursor: grab !important;
    }

    .thumbnail {
        width: 40px;
        height: 40px;
        border: 1px solid #e1e1e1;
        margin: 2px;

        &.gallery-image-current {
            border: 2px solid #81d4cf;
        }
    }
}
.orderable-grid-item-dragged {
    opacity: 0.3;
}
