.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 72px;

    .page-content {
        width: 1000px;
        margin-bottom: 72px;

        .page-title {
            color: #6d6d6d;
            font-size: 24px;
            text-transform: uppercase;
            margin-bottom: 16px;

            .add-button {
                display: inline-block;
                margin-left: 16px;
            }
        }
    }
}
